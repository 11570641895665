import React from "react"

function Header(props) {
    return(
        <div>
            <h1>Meme generator</h1>
        </div>
    )
}

export default Header